import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View, StyleSheet, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { RootStackNavProp } from "./types";
import { useLogin, auth, useResetPassword } from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { Icon } from "react-native-elements";
import Alert from "../components/Alert";
import { useAuthState } from "react-firebase-hooks/auth";

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigation = useNavigation<RootStackNavProp>();
  const [user, loading] = useAuthState(auth);

  const login = async () => {
    const { user, error } = await useLogin(email, password);
    if (!error && user) {
      navigateToAccounts();
    }
    if (error) {
      Alert(
        "Error",
        "There was a problem logging in, please check your username and password.",
        [{ text: "OK", style: "cancel" }]
      );
    }
  };

  const resetPassword = async () => {
    if (!email) {
      Alert("Error", "Please enter your email.", [
        { text: "OK", style: "cancel" },
      ]);
      return;
    }

    const { completed, error } = await useResetPassword(email);

    if (completed && !error) {
      Alert(
        "Success",
        "Successfully reset password. Please check your email, including the spam folder.",
        [{ text: "OK", style: "cancel" }]
      );
    }
    if (error) {
      Alert("Error", "There was a problem resetting your password.", [
        { text: "OK", style: "cancel" },
      ]);
    }
  };

  const navigateToCreateAccount = () => {
    navigation.navigate("Signup");
  };

  const navigateToAccounts = () => {
    navigation.navigate("Accounts");
  };

  const navigateToLanding = () => {
    navigation.navigate("Landing");
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={navigateToLanding}>
          <Image
            style={styles.logo}
            source={require("../../website assets/output-onlinepngtools.png")}
          />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    if (user) {
      navigateToAccounts();
      return;
    }
  }, [user]);

  return (
    <View style={styles.container}>
      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Email"
          value={email}
          onChangeText={setEmail}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Password"
          secureTextEntry={true}
          value={password}
          onChangeText={setPassword}
        />
      </View>

      <TouchableOpacity onPress={resetPassword}>
        <Text style={styles.forgot_button}>Forgot Password?</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.loginButton} onPress={login}>
        <Text>Login</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.secondaryButton}
        onPress={navigateToCreateAccount}
      >
        <Text>Create Account&nbsp;&nbsp;</Text>
        <Icon
          name="angle-right"
          type="font-awesome"
          color="#000000"
          size={16}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  inputView: {
    borderRadius: 30,
    borderBottomWidth: 5,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: 50,
    width: 150,
    position: "relative",
  },

  textInput: {
    height: 50,
    width: "100%",
    textAlign: "center",
    flex: 1,
    padding: 10,
    marginLeft: 20,
  },

  forgot_button: {
    height: 30,
    marginBottom: 30,
  },

  loginButton: {
    flexDirection: "row",
    borderRadius: 25,
    width: "80%",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#b3cee5",
  },

  secondaryButton: {
    flexDirection: "row",
    borderRadius: 25,
    width: "80%",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
});
