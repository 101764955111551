import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { auth, useAddAccount } from "../../api/src/hooks";
import { RootStackNavProp } from "./types";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const AddAccountPage: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [yearlyRev, setYearlyRev] = useState("");
  const [user, loading] = useAuthState(auth);

  const navigateBackToAccountsPage = () => {
    navigation.goBack();
  };

  const saveAccount = async () => {
    const { refID } = await useAddAccount({
      name,
      address,
      type,
      size,
      yearlyRev,
    });
    if (refID) {
      navigateBackToAccountsPage();
    } else {
      Alert("Error", "There was a problem adding this account", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  return (
    <View style={styles.container}>
      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Account Name"
          value={name}
          onChangeText={setName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Address"
          value={address}
          onChangeText={setAddress}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput label="Type" value={type} onChangeText={setType} />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput label="Size" value={size} onChangeText={setSize} />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Yearly Revenue"
          value={yearlyRev}
          onChangeText={setYearlyRev}
        />
      </View>

      <TouchableOpacity style={styles.saveButton} onPress={saveAccount}>
        <Text style={{ color: "white", fontSize: 20 }}>Save</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  textInput: {
    width: "100%",
    textAlign: "center",
    textAlignVertical: "center",
    paddingHorizontal: 10,
    fontSize: 20,
  },

  saveButton: {
    height: 50,
    marginTop: 50,
    marginBottom: 30,
    backgroundColor: "#dda0dd",
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
});
