import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Button,
  ScrollView,
  RefreshControl,
} from "react-native";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { auth, useGetContacts, useGetCurrentUser } from "../../api/src/hooks";
import { SearchBar, Icon } from "react-native-elements";
import { useAuthState } from "react-firebase-hooks/auth";

export const ContactsPage: React.FC = () => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const navigation = useNavigation<RootStackNavProp>();
  const route = useRoute<RootStackScreenProps<"Contacts">["route"]>();
  const account = route.params?.account;

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  useFocusEffect(
    useCallback(() => {
      fetchContacts();
    }, [])
  );

  const fetchContacts = async () => {
    const data = await useGetContacts(account.id);
    if (data.length !== 0) {
      data.sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
    }

    setContacts(data);
    setFilteredContacts(data);
  };

  const filterResults = (searchText) => {
    setSearchText(searchText);
    const newData = contacts.filter((contact) => {
      return (
        contact.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.phoneNumber.includes(searchText) ||
        contact.positionTitle.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredContacts(newData);
  };

  const navigateToAddContact = () => {
    navigation.navigate("AddContact", { account });
  };

  const contactClicked = (contact) => {
    navigation.navigate("ContactDetail", { contact, accountId: account.id });
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchContacts();
    setRefreshing(false);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.listHeader}>
          <SearchBar
            platform="ios"
            containerStyle={styles.searchBar}
            placeholder="Search"
            lightTheme
            round
            autoCorrect={false}
            onChangeText={filterResults}
            value={searchText}
            inputStyle={{ color: "black" }}
            showLoading={false}
            onClear={() => filterResults("")}
            onBlur={undefined}
            loadingProps={undefined}
            onFocus={undefined}
            clearIcon={undefined}
            searchIcon={undefined}
            onCancel={undefined}
            cancelButtonTitle={""}
            cancelButtonProps={undefined}
            showCancel={false}
          />
          <TouchableOpacity
            style={styles.addContactButton}
            onPress={navigateToAddContact}
          >
            <Icon name="plus" type="font-awesome" color="#dda0dd" />
          </TouchableOpacity>
        </View>
        {filteredContacts.map((contact) => (
          <TouchableOpacity
            key={`${contact.firstName}${contact.lastName}${contact.phoneNumber}`}
            style={styles.card}
            onPress={() => contactClicked(contact)}
          >
            <Text style={styles.name}>
              {contact.firstName} {contact.lastName}
            </Text>
            <Text>{contact.phoneNumber}</Text>
            <Text>{contact.positionTitle}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  addContactButton: {
    padding: 1,
  },
  card: {
    backgroundColor: "#FEFEFE",
    borderRadius: 10,
    padding: 20,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 17,
    justifyContent: "center",
    alignItems: "flex-start",
    shadowRadius: 3,
    shadowOpacity: 0.8,
    shadowColor: "rgba(0, 0, 0, 0.24)",
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  listHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  name: {
    fontSize: 20,
    fontWeight: "700",
  },
  searchBar: {
    flex: 1,
    backgroundColor: "transparent",
    padding: 0,
    paddingRight: 10,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
});
