import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { auth, useAddNote } from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import DateTimePicker from "../components/DateTimePicker";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const AddNotePage: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();
  const route = useRoute<RootStackScreenProps<"AddNote">["route"]>();

  const account = route.params?.account;

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  const navigateBack = () => {
    navigation.goBack();
  };

  const saveNote = async () => {
    const { didComplete, error } = await useAddNote(
      { title, description, date },
      account.id
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem creating this note", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Title"
          value={title}
          onChangeText={setTitle}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          height: 70,
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: 16,
          width: "80%",
        }}
      >
        <Text style={{ fontSize: 20, color: "#aaa", paddingRight: 20 }}>
          Date
        </Text>
        <View style={styles.date}>
          <DateTimePicker value={date} onChange={setDate} disabled={false} />
        </View>
      </View>

      <View style={styles.descriptionView}>
        <FloatingLabelInput
          label="Description"
          value={description}
          multiline={true}
          onChangeText={setDescription}
        />
      </View>

      <TouchableOpacity style={styles.saveButton} onPress={saveNote}>
        <Text>Save</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  date: {
    borderRadius: 10,
  },

  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  descriptionView: {
    borderRadius: 10,
    width: "80%",
    height: 300,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  saveButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#98cd98",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },
});
