import React, { useState } from "react";
import { Animated, StyleSheet, TextInput, View } from "react-native";

export const FloatingLabelInput = ({
  label,
  value,
  onChangeText,
  multiline = false,
  phoneNumber = false,
  email = false,
  editable = true,
  secureTextEntry = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? "");

  const animatedLabelTranslateY = new Animated.Value(
    inputValue === "" ? 30 : 0
  );
  const animatedLabelFontSize = new Animated.Value(inputValue === "" ? 20 : 16);

  const handleFocus = () => {
    setIsFocused(true);
    Animated.parallel(
      [
        Animated.timing(animatedLabelTranslateY, {
          toValue: -30,
          useNativeDriver: false,
        }),
        Animated.timing(animatedLabelFontSize, {
          toValue: 16,
          useNativeDriver: false,
        }),
      ],
      {
        duration: 5000,
      }
    ).start();
  };

  const handleBlur = () => {
    setIsFocused(false);
    Animated.parallel(
      [
        Animated.timing(animatedLabelTranslateY, {
          toValue: inputValue === "" ? 30 : 0,
          useNativeDriver: false,
        }),
        Animated.timing(animatedLabelFontSize, {
          toValue: inputValue === "" ? 20 : 16,
          useNativeDriver: false,
        }),
      ],
      {
        duration: 1000,
      }
    ).start();
  };

  const handleTextChange = (text) => {
    setInputValue(text);
    onChangeText(text);
  };

  return (
    <View style={styles.container}>
      <Animated.Text
        style={[
          styles.label,
          {
            transform: [
              {
                translateY: animatedLabelTranslateY,
              },
            ],
            fontSize: animatedLabelFontSize,
            color: isFocused ? "#0066cc" : "#aaa",
          },
        ]}
      >
        {label}
      </Animated.Text>
      <TextInput
        multiline={multiline}
        style={[styles.input, multiline && { height: 300 }]}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChangeText={handleTextChange}
        value={value}
        keyboardType={
          phoneNumber ? "number-pad" : email ? "email-address" : "default"
        }
        clearButtonMode="while-editing"
        editable={editable}
        secureTextEntry={secureTextEntry}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 10,
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    textAlignVertical: "center",
    paddingHorizontal: 10,
    fontSize: 20,
  },
  label: {
    position: "absolute",
    left: 10,
    top: 0,
    fontSize: 16,
    color: "#aaa",
  },
  focusedLabel: {
    color: "#0066cc",
  },
  input: {
    borderRadius: 5,
    paddingTop: 10,
    fontSize: 20,
    color: "#000",
  },
});
