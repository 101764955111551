import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { auth, useAddContact } from "../../api/src/hooks";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const AddContactPage: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();
  const route = useRoute<RootStackScreenProps<"AddContact">["route"]>();

  const account = route.params?.account;

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [positionTitle, setPositionTitle] = useState("");

  const navigateBack = () => {
    navigation.goBack();
  };

  const saveContact = async () => {
    const { didComplete, error } = await useAddContact(
      {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
        positionTitle: positionTitle,
      },
      account.id
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem creating this contact", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputView}>
        <FloatingLabelInput
          label="First name"
          value={firstName}
          onChangeText={setFirstName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Last name"
          value={lastName}
          onChangeText={setLastName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Phone number"
          value={phoneNumber}
          phoneNumber
          onChangeText={setPhoneNumber}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Email"
          value={email}
          email
          onChangeText={setEmail}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Position title"
          value={positionTitle}
          onChangeText={setPositionTitle}
        />
      </View>

      <TouchableOpacity style={styles.saveButton} onPress={saveContact}>
        <Text>Save</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  saveButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#98cd98",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },
});
