import React from "react";
import { createElement } from "react-native-web";

export default function DateTimePicker({ value, onChange, disabled }) {
  return createElement("input", {
    type: "date",
    value: value,
    disabled: disabled,
    onInput: (event) => onChange(event.target.value),
  });
}
