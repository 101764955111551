import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import {
  auth,
  useDeleteAccount,
  useUpdateAccountDetails,
} from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const AccountDetailPage: React.FC = () => {
  const route = useRoute<RootStackScreenProps<"AccountDetail">["route"]>();
  const navigation = useNavigation<RootStackNavProp>();
  const [user, loading] = useAuthState(auth);

  const account = route.params?.account;

  const [name, setName] = useState(account.name);
  const [address, setAddress] = useState(account.address);
  const [type, setType] = useState(account.type);
  const [size, setSize] = useState(account.size);
  const [yearlyRev, setYearlyRev] = useState(account.yearlyRev);

  const [editMode, setEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    navigation.setOptions({ title: account.name });
  }, [navigation, account.name]);

  const editClicked = () => {
    setEditMode(!editMode);
    checkDirty();
  };

  const checkDirty = () => {
    if (
      account.name != name ||
      account.address != address ||
      account.type != type ||
      account.size != size ||
      account.yearlyRev != yearlyRev
    ) {
      setDirty(true);
    } else if (
      account.name == name &&
      account.address == address &&
      account.type == type &&
      account.size == size &&
      account.yearlyRev == yearlyRev
    ) {
      setDirty(false);
    }
  };

  const navigateBack = () => {
    navigation.goBack();
  };

  const saveClicked = async () => {
    const { didComplete, error } = await useUpdateAccountDetails({
      ...account,
      name: name,
      address: address,
      type: type,
      size: size,
      yearlyRev: yearlyRev,
    });
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem updating this account", [
        { text: "OK", style: "cancel" },
      ]);
    }
  };

  const deleteAction = async () => {
    const { didComplete, error } = await useDeleteAccount(account.id);
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem deleting this account", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const deleteClicked = () => {
    Alert(
      "Are you sure?",
      "Your account cannot be recovered once deleted. Are you sure you want to proceed?",
      [
        {
          text: "Yes, delete this account",
          style: "destructive",
          onPress: deleteAction,
        },
        { text: "Cancel", style: "cancel", onPress: () => {} },
      ]
    );
  };

  const notesClicked = () => {
    navigation.navigate("Notes", { account });
  };

  const contactsClicked = () => {
    navigation.navigate("Contacts", { account });
  };

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ alignItems: "center" }}
    >
      <TouchableOpacity style={styles.editButton} onPress={editClicked}>
        <Text>{editMode ? "Done" : "Edit"}</Text>
      </TouchableOpacity>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Account name"
          value={name}
          editable={editMode}
          onChangeText={setName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          value={address}
          label="Address"
          editable={editMode}
          onChangeText={setAddress}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          value={type}
          label="Type"
          editable={editMode}
          onChangeText={setType}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          value={size}
          label="Size"
          editable={editMode}
          onChangeText={setSize}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          value={yearlyRev}
          label="Yearly Revenue"
          editable={editMode}
          onChangeText={setYearlyRev}
        />
      </View>

      <TouchableOpacity
        style={styles.notesAndContactsButton}
        onPress={notesClicked}
      >
        <Text>Notes</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.notesAndContactsButton}
        onPress={contactsClicked}
      >
        <Text>Contacts</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.saveButton,
          !dirty || editMode ? styles.saveButtonDisabled : undefined,
        ]}
        disabled={!dirty || editMode}
        onPress={saveClicked}
      >
        <Text>Save</Text>
      </TouchableOpacity>

      {editMode && (
        <TouchableOpacity style={styles.deleteButton} onPress={deleteClicked}>
          <Text>Delete</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  editButton: {
    alignSelf: "flex-end",
    alignItems: "center",
    backgroundColor: "#b3cee5",
    borderRadius: 10,
    width: 70,
    padding: 10,
    margin: 10,
  },

  deleteButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#FAA0A0",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginTop: 10,
    marginBottom: 40,
  },

  saveButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#98cd98",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },

  saveButtonDisabled: {
    backgroundColor: "#DBDBD0",
  },

  container: {
    flex: 1,
    backgroundColor: "#fff",
  },

  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  notesAndContactsButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#b3cee5",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },

  textInput: {
    width: "100%",
    textAlign: "center",
    textAlignVertical: "center",
    paddingHorizontal: 10,
    fontSize: 20,
  },
});
