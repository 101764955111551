import React from "react";
import { StyleSheet } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import { AccountsPage } from "./src/pages/AccountsPage";
import { AddAccountPage } from "./src/pages/AddAccountPage";
import { LoginPage } from "./src/pages/LoginPage";
import { RootStackParamList } from "./src/pages/types";
import { AccountDetailPage } from "./src/pages/AccountDetailPage";
import { NotesPage } from "./src/pages/NotesPage";
import { ContactsPage } from "./src/pages/ContactsPage";
import { AddNotePage } from "./src/pages/AddNotePage";
import { AddContactPage } from "./src/pages/AddContactPage";
import { NoteDetailPage } from "./src/pages/NoteDetailPage";
import { ContactDetailPage } from "./src/pages/ContactDetailPage";
import { LandingPage } from "./src/pages/LandingPage";
import { AboutPage } from "./src/pages/AboutPage";
import { SignupPage } from "./src/pages/SignupPage";

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  const linking = {
    config: {
      screens: {
        About: "About",
        Login: "Login",
        Signup: "Signup",
        Accounts: "Accounts",
        AddAccount: "AddAccount",
        AccountDetail: "AccountDetail",
        Notes: "Notes",
        AddNote: "AddNote",
        NoteDetail: "NoteDetail",
        Contacts: "Contacts",
        AddContact: "AddContact",
        ContactDetail: "ContactDetail",
      },
    },
    prefixes: [],
  };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="Landing">
        <Stack.Screen name="Landing" component={LandingPage} />
        <Stack.Screen
          name="Login"
          component={LoginPage}
          options={{
            title: "Login",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="Signup"
          component={SignupPage}
          options={{
            title: "Signup",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="About"
          component={AboutPage}
          options={{
            title: "About",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="Accounts"
          component={AccountsPage}
          options={{
            title: "Accounts",
            headerBackVisible: false,
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="AddAccount"
          component={AddAccountPage}
          options={{
            title: "Add Account",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="AccountDetail"
          component={AccountDetailPage}
          options={{
            title: "Account",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="Notes"
          component={NotesPage}
          options={{
            title: "Notes",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="AddNote"
          component={AddNotePage}
          options={{
            title: "Add Note",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="NoteDetail"
          component={NoteDetailPage}
          options={{
            title: "Note",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="Contacts"
          component={ContactsPage}
          options={{
            title: "Contacts",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="AddContact"
          component={AddContactPage}
          options={{
            title: "Add Contact",
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="ContactDetail"
          component={ContactDetailPage}
          options={{
            title: "Contact",
            headerTitleAlign: "center",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
