import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  initializeAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  collection,
  getFirestore,
  setDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { FIREBASE_CONFIG } from "../../src/constants/constants";
import { getReactNativePersistence } from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { sampleData } from "../../mocks/sampleData";

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});
const db = getFirestore(app);
export const auth = getAuth(app);

/**
 *
 * Gets currently logged in user
 *
 * @returns user or null
 */
export function useGetCurrentUser() {
  const auth = getAuth();
  const user = auth.currentUser;

  return user ? user : null;
}

/////////////////////////////////////////////////////////////////////
// Login/Signup/Signout
/////////////////////////////////////////////////////////////////////

/**
 * Firebase Login
 *
 * @param email user's email
 * @param password user's password
 * @returns user or error
 */
export async function useLogin(email, password) {
  let user;
  let error;

  await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in!
      user = userCredential.user;
    })
    .catch((authError) => {
      const errorCode = authError.code;
      const errorMessage = authError.message;
      error = authError;
    });

  return { user: user, error: error };
}

/**
 * Firebase creates new user
 *
 * @param email user's email
 * @param password user's password
 * @returns new user or error
 */
export async function useCreateAccount(email, password) {
  let user;
  let error;

  await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Created user!
      user = userCredential.user;
    })
    .catch((authError) => {
      const errorCode = authError.code;
      const errorMessage = authError.message;
      error = authError;
    });

  // Add a new user document with user's uid.
  const currentUser = useGetCurrentUser();
  if (currentUser) {
    await setDoc(doc(db, "Users", currentUser.uid), {});

    /**
     * Right now we're just calling the other hooks to add 2 sample accounts and associating notes/contacts
     * but it means we have to wait a bit before navigating to accounts page. in the future bundling these
     * or reducing requests would be ideal, this is unnecessary and was only done for speed hacking :S
     */

    // Add sample account A
    const newDocRef = doc(collection(db, `Users/${currentUser.uid}/Accounts`));
    await setDoc(newDocRef, {
      account: { ...sampleData.sampleAccountA, id: newDocRef.id },
    });

    // Add sample account B
    const newDocRef2 = doc(collection(db, `Users/${currentUser.uid}/Accounts`));
    await setDoc(newDocRef2, {
      account: { ...sampleData.sampleAccountB, id: newDocRef2.id },
    });

    const accounts = await useGetAccounts();

    // Add sample note A
    const sampleAccountAId = accounts[0][0];
    const sampleAccountBId = accounts[1][0];

    const {} = await useAddNote(sampleData.sampleNoteA, sampleAccountAId);

    // Add sample note B
    const {} = await useAddNote(sampleData.sampleNoteB, sampleAccountBId);

    // Add sample contact A1 + A2
    const {} = await useAddContact(
      sampleData.sampleContactA1,
      sampleAccountAId
    );

    const {} = await useAddContact(
      sampleData.sampleContactA2,
      sampleAccountAId
    );

    // Add sample contact B1 + B2
    const {} = await useAddContact(
      sampleData.sampleContactB1,
      sampleAccountBId
    );

    const {} = await useAddContact(
      sampleData.sampleContactB2,
      sampleAccountBId
    );
  }

  return { user: user, error: error };
}

export async function useResetPassword(email) {
  let completed;
  let error;
  await sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      completed = true;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      error = error;
    });

  return { completed: completed, error: error };
}

export async function useLogOut() {
  let didComplete = false;
  let error = undefined;
  await signOut(auth)
    .then(() => {
      didComplete = true;
    })
    .catch((error) => (error = error));

  return { didComplete, error };
}

/////////////////////////////////////////////////////////////////////
// Add/Update/Remove/Get user's accounts
/////////////////////////////////////////////////////////////////////

/**
 *
 * Adds an account to user's accounts list
 *
 * @param newAccount account to add to user's list of accounts
 * @returns reference id to new account
 */
export async function useAddAccount(newAccount) {
  const currentUser = useGetCurrentUser();
  const empty = [];
  if (currentUser) {
    const newDocRef = doc(collection(db, `Users/${currentUser.uid}/Accounts`));
    await setDoc(newDocRef, {
      account: { ...newAccount, id: newDocRef.id },
      notes: { empty },
      contacts: { empty },
    });
    return { refID: newDocRef.id };
  }
}

/**
 *
 * Updates an account from user's account list.
 * AccountDetailPage handles checking for dirty updates, so
 * here we are sure the updatedAccount is different from what's already in db
 *
 * @param updatedAccount a changed account to update
 */
export async function useUpdateAccountDetails(updatedAccount) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let myError = undefined;
  if (currentUser) {
    const docRef = doc(
      db,
      `Users/${currentUser.uid}/Accounts/${updatedAccount.id}`
    );
    await updateDoc(docRef, {
      account: updatedAccount,
    })
      .then((docRef) => (didComplete = true))
      .catch((error) => (myError = error));
  }
  return { didComplete, error: myError };
}

/**
 *
 * Returns a list of this user's accounts
 *
 * @returns all accounts for this user
 */
export async function useGetAccounts(user?) {
  const currentUser = useGetCurrentUser(); //user ? user : useGetCurrentUser();
  const docs = [];
  if (!currentUser) return;
  const querySnapshot = await getDocs(
    collection(db, `Users/${currentUser.uid}/Accounts`)
  );
  querySnapshot.forEach((doc) => {
    docs.push([doc.id, doc.data()]);
  });
  return docs;
}

/**
 *
 * Deletes an account from user's accounts list
 *
 * @param accountIdToRemove accountId to remove
 */
export async function useDeleteAccount(accountIdToRemove) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const accountsRef = doc(
      db,
      `Users/${currentUser.uid}/Accounts/${accountIdToRemove}`
    );
    let accounts = await useGetAccounts();
    const index = accounts.findIndex(
      (account) => account[0] === accountIdToRemove
    );
    if (index !== -1) {
      accounts.splice(index, 1);
      //if (accounts.length === 0) accounts = [];
      await deleteDoc(accountsRef)
        .then(() => {
          didComplete = true;
        })
        .catch((err) => (error = err));
    }
  }
  return { didComplete, error };
}

/////////////////////////////////////////////////////////////////////
// Add/Update/Delete/Get notes for an account
/////////////////////////////////////////////////////////////////////

/**
 *
 * Adds a note to list of notes under this account
 *
 * @param note the note to add
 * @param account the account to add the note under
 * @returns true if completed successfully, otherwise error
 */
export async function useAddNote(note, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const notesRef = doc(db, `Users/${currentUser.uid}/Accounts/${accountId}`);
    const newNoteRef = doc(
      collection(db, `Users/${currentUser.uid}/Accounts/${accountId}/notes`)
    );
    await updateDoc(notesRef, {
      notes: arrayUnion({ ...note, id: `${newNoteRef.id}` }),
    })
      .then(() => {
        didComplete = true;
        return;
      })
      .catch((error) => {
        error = error;
        return;
      });
  }
  return { didComplete, error };
}

/**
 *
 * Gets all notes for a given account from user's account list
 *
 * @param account account to fetch notes for
 * @returns all notes under this account, or empty list
 */
export async function useGetNotes(accountId) {
  const currentUser = useGetCurrentUser();
  if (!currentUser) return [];
  const docSnapshot = await getDoc(
    doc(db, `Users/${currentUser.uid}/Accounts/${accountId}`)
  );
  if (docSnapshot.exists()) {
    if (docSnapshot.data()["notes"]) {
      return docSnapshot.data()["notes"];
    } else {
      return [];
    }
  } else return [];
}

/**
 *
 * Takes in a note and saves it on top of previous note
 *
 * @param updatedNote the note to overwrite with db's note
 */
export async function useUpdateNote(updatedNote, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const notesRef = doc(db, "Users", currentUser.uid, "Accounts", accountId);
    const notes = await useGetNotes(accountId);
    const index = notes.findIndex((note) => note.id === updatedNote.id);
    if (index !== -1) {
      notes[index] = updatedNote;
      await updateDoc(notesRef, {
        notes: notes,
      })
        .then(() => {
          didComplete = true;
        })
        .catch((err) => (error = err));
    }
  }
  return { didComplete, error };
}

/**
 *
 * Deletes a note completely from this account's notes list
 *
 * @param note note to remove
 * @param accountId account to remove note from
 */
export async function useDeleteNote(noteToDelete, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const notesRef = doc(db, "Users", currentUser.uid, "Accounts", accountId);
    let notes = await useGetNotes(accountId);
    const index = notes.findIndex((note) => note.id === noteToDelete.id);
    if (index !== -1) {
      notes.splice(index, 1);
      await updateDoc(notesRef, {
        notes: notes,
      })
        .then(() => {
          didComplete = true;
        })
        .catch((err) => (error = err));
    }
  }
  return { didComplete, error };
}

/////////////////////////////////////////////////////////////////////
// Add/Update/Delete/Get contacts for an account
/////////////////////////////////////////////////////////////////////

/**
 *
 * Adds a contact to this account's contacts list
 *
 * @param contact contact to add
 * @param accountId the account to add the contact to
 * @returns true if completed successfully, otherwise error
 */
export async function useAddContact(contact, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const contactsRef = doc(
      db,
      `Users/${currentUser.uid}/Accounts/${accountId}`
    );
    const newContactRef = doc(
      collection(db, `Users/${currentUser.uid}/Accounts/${accountId}/contacts`)
    );
    await updateDoc(contactsRef, {
      contacts: arrayUnion({ ...contact, id: `${newContactRef.id}` }),
    })
      .then(() => {
        didComplete = true;
        return;
      })
      .catch((error) => {
        error = error;
        return;
      });
  }
  return { didComplete, error };
}

/**
 *
 * Gets all the contacts for given account
 *
 * @param accountId accountId to fetch contacts from
 * @returns all contacts under this account or empty list
 */
export async function useGetContacts(accountId) {
  const currentUser = useGetCurrentUser();
  if (!currentUser) return [];
  const docSnapshot = await getDoc(
    doc(db, `Users/${currentUser.uid}/Accounts/${accountId}`)
  );
  if (docSnapshot.exists()) {
    if (docSnapshot.data()["contacts"]) {
      return docSnapshot.data()["contacts"];
    } else {
      return [];
    }
  } else return [];
}

/**
 *
 * Updates a contact from account's contacts list
 *
 * @param contact contact to update
 * @param accountId accountId to update contact from
 */
export async function useUpdateContact(updatedContact, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const contactsRef = doc(
      db,
      "Users",
      currentUser.uid,
      "Accounts",
      accountId
    );
    const contacts = await useGetContacts(accountId);
    const index = contacts.findIndex(
      (contact) => contact.id === updatedContact.id
    );
    if (index !== -1) {
      contacts[index] = updatedContact;
      await updateDoc(contactsRef, {
        contacts: contacts,
      })
        .then(() => {
          didComplete = true;
        })
        .catch((err) => (error = err));
    }
  }
  return { didComplete, error };
}

/**
 *
 * Deletes a contact from account's contacts list
 *
 * @param contactToDelete contact to remove
 * @param accountId accountId to remove contact from
 */
export async function useDeleteContact(contactToDelete, accountId) {
  const currentUser = useGetCurrentUser();
  let didComplete = false;
  let error = undefined;
  if (currentUser) {
    const contactsRef = doc(
      db,
      "Users",
      currentUser.uid,
      "Accounts",
      accountId
    );
    let contacts = await useGetContacts(accountId);
    const index = contacts.findIndex(
      (contact) => contact.id === contactToDelete.id
    );
    if (index !== -1) {
      contacts.splice(index, 1);
      await updateDoc(contactsRef, {
        contacts: contacts,
      })
        .then(() => {
          didComplete = true;
        })
        .catch((err) => (error = err));
    }
  }
  return { didComplete, error };
}
