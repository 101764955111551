import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import { RootStackNavProp } from "./types";
import { Linking } from "react-native";
import { LandingPageHeader } from "./LandingPageComponents/Header";

export const AboutPage: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();
  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <ScrollView
      stickyHeaderIndices={[0]}
      style={{ backgroundColor: "#ded3f8" }}
    >
      <LandingPageHeader />
      <View style={styles.content}>
        <Text style={{ fontSize: 50, fontWeight: "700" }}>About us</Text>
        <Text
          style={{
            fontSize: 25,
            fontWeight: "500",
            paddingHorizontal: 50,
            marginTop: 100,
          }}
        >
          The most important aspect of sales is human interactions. Noteworthy
          is a CRM that is solely devoted to that. It allows you to document
          those moments in real time, readily available for reference at any
          point in the future without having to navigate complicated dashboards
          or data sets.
        </Text>
        <Text
          style={{
            fontSize: 50,
            fontWeight: "700",
            marginTop: 100,
            paddingHorizontal: 50,
          }}
        >
          Meet our founders
        </Text>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 100,
            justifyContent: "center",
          }}
        >
          <TouchableOpacity
            style={styles.card}
            onPress={() =>
              Linking.openURL(
                "https://www.linkedin.com/in/tyler-hayes-5708784b/"
              )
            }
          >
            <Image
              style={{
                height: 250,
                width: 260,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              source={require("../../assets/tyler.jpeg")}
            ></Image>
            <Text style={{ padding: 10, fontSize: 30, fontWeight: "500" }}>
              Tyler Hayes
            </Text>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: "500" }}>
              10+ years in medical sales. Guitar enthusiast.
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.card}
            onPress={() =>
              Linking.openURL("https://www.linkedin.com/in/daniel-andreu/")
            }
          >
            <Image
              style={{
                height: 250,
                width: 260,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              source={require("../../assets/daniel.jpeg")}
            ></Image>
            <Text style={{ padding: 10, fontSize: 30, fontWeight: "500" }}>
              Daniel Andreu
            </Text>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: "500" }}>
              7+ years in hospital administration and market development. Avid
              muralist.
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.card}
            onPress={() =>
              Linking.openURL("https://www.linkedin.com/in/joey-dafforn/")
            }
          >
            <Image
              style={{
                height: 250,
                width: 260,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              source={require("../../assets/joey.jpeg")}
            ></Image>
            <Text style={{ padding: 10, fontSize: 30, fontWeight: "500" }}>
              Joey Dafforn
            </Text>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: "500" }}>
              5+ years in software engineering. Likes to bake.
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={{ alignSelf: "center" }}>©2023 Noteworthy, Inc.</Text>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  card: {
    height: 450,
    width: 260,
    margin: 10,
    borderRadius: 10,
    shadowRadius: 3,
  },
  content: {
    flex: 1,
    marginVertical: 100,
    flexDirection: "column",
    alignItems: "center",
  },
});
