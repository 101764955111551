import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  RefreshControl,
  ScrollView,
} from "react-native";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { auth, useGetCurrentUser, useGetNotes } from "../../api/src/hooks";
import { SearchBar, Icon } from "react-native-elements";
import { useAuthState } from "react-firebase-hooks/auth";

export const NotesPage: React.FC = () => {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigation = useNavigation<RootStackNavProp>();
  const route = useRoute<RootStackScreenProps<"Notes">["route"]>();
  const account = route.params?.account;

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  useFocusEffect(
    useCallback(() => {
      fetchNotes();
    }, [])
  );

  const fetchNotes = async () => {
    const data = await useGetNotes(account.id);
    if (data.length !== 0) {
      data.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }

    setNotes(data);
    setFilteredNotes(data);
  };

  const filterResults = (searchText) => {
    setSearchText(searchText);
    const newData = notes.filter((note) => {
      return (
        note.title.toLowerCase().includes(searchText.toLowerCase()) ||
        note.description.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredNotes(newData);
  };

  const navigateToAddNote = () => {
    navigation.navigate("AddNote", { account });
  };

  const noteClicked = (note) => {
    navigation.navigate("NoteDetail", { note, accountId: account.id });
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchNotes();
    setRefreshing(false);
  }, []);

  return (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      contentContainerStyle={{ paddingBottom: 40 }}
    >
      <View style={styles.listHeader}>
        <SearchBar
          platform="ios"
          containerStyle={styles.searchBar}
          placeholder="Search"
          lightTheme
          round
          autoCorrect={false}
          onChangeText={filterResults}
          value={searchText}
          inputStyle={{ color: "black" }}
          showLoading={false}
          onClear={() => filterResults("")}
          cancelButtonTitle={""}
          showCancel={false}
          onBlur={undefined}
          loadingProps={undefined}
          onFocus={undefined}
          clearIcon={undefined}
          searchIcon={undefined}
          onCancel={undefined}
          cancelButtonProps={undefined}
        />
        <TouchableOpacity
          style={styles.addAccountButton}
          onPress={navigateToAddNote}
        >
          <Icon name="plus" type="font-awesome" color="#dda0dd" />
        </TouchableOpacity>
      </View>
      {filteredNotes.map((note) => (
        <TouchableOpacity
          key={note.title}
          style={styles.card}
          onPress={() => noteClicked(note)}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text style={styles.noteTitle}>{note.title}</Text>
            {note.date && (
              <Text style={styles.noteDate}>
                {new Date(note.date.replace(/-/g, "/")).toLocaleDateString()}
              </Text>
            )}
          </View>
          <Text style={styles.noteDescription}>{note.description}</Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  addAccountButton: {
    padding: 1,
  },
  card: {
    backgroundColor: "#FEFEFE",
    borderRadius: 10,
    padding: 20,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 17,
    justifyContent: "center",
    alignItems: "flex-start",
    shadowRadius: 3,
    shadowOpacity: 0.8,
    shadowColor: "rgba(0, 0, 0, 0.24)",
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  listHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  noteTitle: {
    fontSize: 20,
    fontWeight: "700",
  },
  noteDate: {
    fontSize: 14,
    color: "grey",
    alignSelf: "flex-end",
  },
  noteDescription: {
    fontSize: 14,
    color: "grey",
  },
  searchBar: {
    flex: 1,
    backgroundColor: "transparent",
    padding: 0,
    paddingRight: 10,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
});
