import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  RefreshControl,
} from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { RootStackNavProp } from "./types";
import { auth, useGetAccounts, useLogOut } from "../../api/src/hooks";
import { SearchBar, Icon } from "react-native-elements";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const AccountsPage: React.FC = () => {
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigation = useNavigation<RootStackNavProp>();
  const [refreshing, setRefreshing] = useState(false);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity style={styles.logoutButton} onPress={onLogout}>
          <Text>Logout&nbsp;</Text>
          <Icon
            name="power-off"
            type="font-awesome"
            color="#dda0dd"
            size={16}
          />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      fetchAccounts(user);
    }, [user])
  );

  const onLogout = async () => {
    const { didComplete, error } = await useLogOut();
    if (didComplete) {
      navigation.navigate("Login");
    } else {
      Alert("Error", "There was a problem logging out", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const fetchAccounts = async (user) => {
    const data = await useGetAccounts(user);
    // Note: Data contains each account as well as notes and contacts which are separate collections:
    // {accounts: [Object], contacts: [Array], notes: [Array] }
    data?.sort((a, b) => a[1].account.name.localeCompare(b[1].account.name));

    setAccounts(data);
    setFilteredAccounts(data);
  };

  const navigateToAddAccount = () => {
    navigation.navigate("AddAccount");
  };

  const navigateToAccountDetailed = (account) => {
    navigation.navigate("AccountDetail", { account });
  };

  const filterResults = (searchText: string) => {
    setSearchText(searchText);
    const newData = accounts.filter((account) => {
      return account[1].account.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
    setFilteredAccounts(newData);
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchAccounts(user);
    setRefreshing(false);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={filteredAccounts}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        ListHeaderComponent={
          <View style={styles.listHeader}>
            <SearchBar
              platform="ios"
              containerStyle={styles.searchBar}
              placeholder="Search"
              lightTheme
              round
              autoCorrect={false}
              onChangeText={filterResults}
              value={searchText}
              inputStyle={{ color: "black" }}
              showLoading={false}
              onClear={() => filterResults("")}
              onBlur={undefined}
              loadingProps={undefined}
              onFocus={undefined}
              clearIcon={undefined}
              searchIcon={undefined}
              onCancel={undefined}
              cancelButtonTitle={""}
              cancelButtonProps={undefined}
              showCancel={false}
            />
            <TouchableOpacity
              style={styles.addAccountButton}
              onPress={navigateToAddAccount}
            >
              <Icon name="plus" type="font-awesome" color="#dda0dd" />
            </TouchableOpacity>
          </View>
        }
        renderItem={(account) => {
          return (
            <TouchableOpacity
              style={styles.accountCard}
              onPress={() => navigateToAccountDetailed(account.item[1].account)}
            >
              <Text style={styles.accountName}>
                {account.item[1].account.name}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  accountCard: {
    backgroundColor: "#FEFEFE",
    borderRadius: 10,
    padding: 20,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 17,
    justifyContent: "center",
    alignItems: "flex-start",
    shadowRadius: 3,
    shadowOpacity: 0.8,
    shadowColor: "rgba(0, 0, 0, 0.24)",
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  accountName: {
    fontSize: 20,
  },
  addAccountButton: {
    padding: 1,
  },
  listHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  logoutButton: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    width: 80,
    padding: 10,
  },
  searchBar: {
    flex: 1,
    backgroundColor: "transparent",
    padding: 0,
    paddingRight: 10,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
});
