import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View, StyleSheet, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { RootStackNavProp } from "./types";
import { useLogin, useCreateAccount } from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { Icon } from "react-native-elements";
import Alert from "../components/Alert";

export const SignupPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Navigation
  const navigation = useNavigation<RootStackNavProp>();

  const createAccount = async () => {
    if (password !== confirmPassword) {
      Alert("Password mismatch", "Please make sure the passwords match", [
        { text: "OK", style: "cancel" },
      ]);
      return;
    }
    const { user, error } = await useCreateAccount(email, password);
    if (!error && user) {
      navigateToAccounts();
    }
    if (error) {
      Alert(
        "Error",
        "There was a problem logging in, please check your username and password.",
        [{ text: "OK", style: "cancel" }]
      );
    }
  };

  const navigateToLogin = () => {
    navigation.navigate("Login");
  };
  const navigateToAccounts = () => {
    navigation.navigate("Accounts");
  };
  const navigateToLanding = () => {
    navigation.navigate("Landing");
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={navigateToLanding}>
          <Image
            style={styles.logo}
            source={require("../../website assets/output-onlinepngtools.png")}
          />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Email"
          value={email}
          onChangeText={setEmail}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Password"
          secureTextEntry={true}
          value={password}
          onChangeText={setPassword}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Confirm Password"
          secureTextEntry={true}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
        />
      </View>

      <TouchableOpacity style={styles.loginButton} onPress={createAccount}>
        <Text>Create Account</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.secondaryButton}
        onPress={navigateToLogin}
      >
        <Text>Login&nbsp;&nbsp;</Text>
        <Icon
          name="angle-right"
          type="font-awesome"
          color="#000000"
          size={16}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },

  inputView: {
    borderRadius: 30,
    borderBottomWidth: 5,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: 50,
    width: 150,
    position: "relative",
  },

  textInput: {
    height: 50,
    width: "100%",
    textAlign: "center",
    flex: 1,
    padding: 10,
    marginLeft: 20,
  },

  forgot_button: {
    height: 30,
    marginBottom: 30,
  },

  loginButton: {
    flexDirection: "row",
    borderRadius: 25,
    width: "80%",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#b3cee5",
  },

  secondaryButton: {
    flexDirection: "row",
    borderRadius: 25,
    width: "80%",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
});
