import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { auth, useDeleteContact, useUpdateContact } from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const ContactDetailPage: React.FC = () => {
  const route = useRoute<RootStackScreenProps<"ContactDetail">["route"]>();
  const navigation = useNavigation<RootStackNavProp>();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  const contact = route.params?.contact;
  const accountId = route.params?.accountId;

  const [editMode, setEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [firstName, setFirstName] = useState(contact.firstName);
  const [lastName, setLastName] = useState(contact.lastName);
  const [phoneNumber, setPhoneNumber] = useState(contact.phoneNumber);
  const [email, setEmail] = useState(contact.email);
  const [positionTitle, setPositionTitle] = useState(contact.positionTitle);

  useEffect(() => {
    navigation.setOptions({
      title: `${contact.firstName} ${contact.lastName}`,
    });
  }, [navigation]);

  const editClicked = () => {
    setEditMode(!editMode);
    checkDirty();
  };

  const checkDirty = () => {
    if (
      contact.firstName != firstName ||
      contact.lastName != lastName ||
      contact.phoneNumber != phoneNumber ||
      contact.email != email ||
      contact.positionTitle != positionTitle
    ) {
      setDirty(true);
    } else if (
      contact.firstName == firstName &&
      contact.lastName == lastName &&
      contact.phoneNumber == phoneNumber &&
      contact.email == email &&
      contact.positionTitle == positionTitle
    ) {
      setDirty(false);
    }
  };

  const saveClicked = async () => {
    const { didComplete, error } = await useUpdateContact(
      {
        ...contact,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
        positionTitle: positionTitle,
      },
      accountId
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem updating this contact", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const deleteAction = async () => {
    const { didComplete, error } = await useDeleteContact(
      {
        ...contact,
      },
      accountId
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem deleting this contact", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const deleteClicked = () => {
    Alert(
      "Are you sure?",
      "This contact cannot be recovered once deleted. Are you sure you want to proceed?",
      [
        {
          text: "Yes, delete this contact",
          style: "destructive",
          onPress: deleteAction,
        },
        { text: "Cancel", style: "cancel", onPress: () => {} },
      ]
    );
  };

  const navigateBack = () => {
    navigation.goBack();
  };

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ alignItems: "center", justifyContent: "center" }}
    >
      <TouchableOpacity style={styles.editButton} onPress={editClicked}>
        <Text>{editMode ? "Done" : "Edit"}</Text>
      </TouchableOpacity>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="First name"
          value={firstName}
          editable={editMode}
          onChangeText={setFirstName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Last name"
          value={lastName}
          editable={editMode}
          onChangeText={setLastName}
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Phone number"
          value={phoneNumber}
          editable={editMode}
          onChangeText={setPhoneNumber}
          phoneNumber
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Email"
          value={email}
          editable={editMode}
          onChangeText={setEmail}
          email
        />
      </View>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Position title"
          value={positionTitle}
          editable={editMode}
          onChangeText={setPositionTitle}
        />
      </View>

      <TouchableOpacity
        style={[
          styles.saveButton,
          !dirty || editMode ? styles.saveButtonDisabled : undefined,
        ]}
        disabled={!dirty || editMode}
        onPress={saveClicked}
      >
        <Text>Save</Text>
      </TouchableOpacity>

      {editMode && (
        <TouchableOpacity style={styles.deleteButton} onPress={deleteClicked}>
          <Text>Delete</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  deleteButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#FAA0A0",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginTop: 10,
    marginBottom: 40,
  },
  descriptionView: {
    borderRadius: 10,
    width: "80%",
    height: 400,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  editButton: {
    alignSelf: "flex-end",
    alignItems: "center",
    backgroundColor: "#b3cee5",
    borderRadius: 10,
    width: 70,
    padding: 10,
    margin: 10,
  },
  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  saveButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#98cd98",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },
  saveButtonDisabled: {
    backgroundColor: "#DBDBD0",
  },
});
