import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import { RootStackNavProp } from "../types";
import { LinearGradient } from "expo-linear-gradient";

export const LandingPageHeader: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();
  const { height, width } = useWindowDimensions();
  const mobile = width < 600;

  const navigateToLogin = () => {
    navigation.navigate("Login");
  };

  const navigateToAbout = () => {
    navigation.navigate("About");
  };

  const navigateToLanding = () => {
    navigation.navigate("Landing");
  };

  return (
    <LinearGradient colors={["#ffffff", "#ded3f8"]} style={styles.header}>
      <TouchableOpacity onPress={navigateToLanding}>
        <Image
          style={styles.logo}
          source={require("../../../website assets/output-onlinepngtools.png")}
        />
      </TouchableOpacity>
      <View style={styles.text}>
        {!mobile && (
          <TouchableOpacity style={styles.aboutText} onPress={navigateToAbout}>
            <Text style={{ fontSize: 15, fontWeight: "700", padding: 10 }}>
              About
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={styles.getStartedText}
          onPress={navigateToLogin}
        >
          <Text style={{ fontSize: 15, fontWeight: "700", padding: 10 }}>
            Get started
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  aboutText: {
    backgroundColor: "#95d4f5",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  getStartedText: {
    marginLeft: 20,
    backgroundColor: "#95d4f5",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  text: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    backgroundColor: "#ffffff",
    opacity: 0.95,
    height: 90,
    paddingTop: 20,
    paddingHorizontal: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: 50,
    width: 150,
    position: "relative",
  },
});
