import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import { RootStackNavProp } from "./types";
import { LinearGradient } from "expo-linear-gradient";
import { LandingPageHeader } from "./LandingPageComponents/Header";

export const LandingPage: React.FC = () => {
  const navigation = useNavigation<RootStackNavProp>();
  const { height, width } = useWindowDimensions();
  const mobile = width < 600;
  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  const navigateToLogin = () => {
    navigation.navigate("Login");
  };

  const navigateToAbout = () => {
    navigation.navigate("About");
  };

  const navigateToLanding = () => {
    navigation.navigate("Landing");
  };

  return (
    <ScrollView
      stickyHeaderIndices={[0]}
      style={{ backgroundColor: "#ffffff" }}
    >
      <LandingPageHeader />
      <LinearGradient colors={["#ded3f8", "#fce9f6"]} style={styles.view}>
        <Text style={[styles.firstSectionHeader, mobile && { fontSize: 50 }]}>
          Your personal CRM- 100% free, forever!
        </Text>
        <Text style={styles.firstSectionSubHeader}>
          Simplify medical sales account management, own your data, and have a
          better understanding of your customers- even when you move on to
          another company.
        </Text>
        <TouchableOpacity style={styles.getStarted} onPress={navigateToLogin}>
          <Text style={styles.getStartedText}>Get started</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.getStarted} onPress={navigateToAbout}>
          <Text style={styles.getStartedText}>About Us</Text>
        </TouchableOpacity>
        <Image
          style={styles.firstImage}
          source={require("../../assets/landingPic1Alt.png")}
        />
        <View style={styles.secondView}>
          <Text style={styles.secondSectionHeader}>
            Complex accounts, simplified
          </Text>
          <Text style={styles.secondSectionSubheader}>
            Noteworthy simplifies medical sales reps' lives by streamlining
            complex account management, enabling them to focus on building
            relationships and achieving success. Trust us to make your work
            easier.
          </Text>
        </View>
        <View style={styles.thirdView}>
          <Text style={styles.thirdSectionHeader}>
            Efficient account management
          </Text>
          <Text style={styles.thirdSectionSubheader}>
            Noteworthy's efficient account management feature streamlines the
            medical sales processes by organizing client information with more
            freedom and flexibility than your company-provided CRM.
          </Text>
        </View>
        <Text>©2023 Noteworthy, Inc.</Text>
      </LinearGradient>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  aboutText: {
    backgroundColor: "#95d4f5",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  firstImage: {
    borderRadius: 10,
    height: 300,
    width: 600,
    marginTop: 50,
    marginHorizontal: 10,
  },
  getStarted: {
    width: 275,
    height: 48,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#95d4f5",
    marginTop: 10,
  },
  getStartedText: {
    fontFamily: "Menlo-Bold",
    fontSize: 20,
  },
  firstSectionHeader: {
    fontSize: 80,
    fontFamily: "Menlo-Bold",
    paddingBottom: 45,
    paddingTop: 50,
    paddingHorizontal: 20,
    textAlign: "center",
  },
  header: {
    backgroundColor: "#ffffff",
    opacity: 0.95,
    height: 90,
    paddingTop: 20,
    paddingHorizontal: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 10,
  },
  headerText: {
    display: "flex",
    flexDirection: "row",
  },
  goToAppText: {
    marginLeft: 40,
    backgroundColor: "#95d4f5",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  logo: {
    height: 50,
    width: 150,
    position: "relative",
  },
  firstSectionSubHeader: {
    fontSize: 20,
    fontFamily: "Menlo",
    color: "#404040",
    maxWidth: 600,
    paddingHorizontal: 20,
    paddingBottom: 35,
    textAlign: "center",
  },
  secondView: {
    marginTop: 20,
    minHeight: 300,
    width: "100%",
    alignItems: "center",
    backgroundColor: "#66adff",
  },
  secondSectionHeader: {
    fontSize: 50,
    fontFamily: "Menlo-Bold",
    paddingVertical: 45,
    paddingHorizontal: 20,
    textAlign: "center",
    color: "white",
    maxWidth: 600,
  },
  secondSectionSubheader: {
    fontSize: 20,
    fontFamily: "Menlo",
    color: "white",
    paddingBottom: 35,
    paddingHorizontal: 30,
    textAlign: "center",
    maxWidth: 600,
  },
  view: {
    flex: 1,
    flexDirection: "column",
    height: 950,
    alignItems: "center",
  },
  linearGradient: {
    padding: 15,
    alignItems: "center",
    borderRadius: 5,
  },
  thirdView: {
    minHeight: 500,
    marginTop: 50,
    alignItems: "center",
  },
  thirdSectionHeader: {
    fontSize: 50,
    fontFamily: "Menlo-Bold",
    paddingVertical: 45,
    maxWidth: 600,
    paddingHorizontal: 20,
    textAlign: "center",
    color: "black",
  },
  thirdSectionSubheader: {
    fontSize: 20,
    fontFamily: "Menlo",
    maxWidth: 600,
    color: "black",
    paddingBottom: 35,
    paddingHorizontal: 30,
    textAlign: "center",
  },
});
