const sampleAccountA = {
  name: "Arlington Hospital - SAMPLE",
  address: "1234 Main St, Carmel, IN 46033",
  type: "General",
  size: "Large",
  yearlyRev: "1000000",
};

const sampleNoteA = {
  title: "June 16th lunch",
  description:
    "Met with Dr. Chapman and Kevin. They want to move forward with new surgical equipment. The budget committee meets next Thursday. Follow up with Barbara from purchasing.",
  date: "2023-01-01",
};

const sampleContactA1 = {
  firstName: "Kevin",
  lastName: "Thomas",
  phoneNumber: "3175550156",
  email: "kevinthomas@example.com",
  positionTitle: "Nurse",
};

const sampleContactA2 = {
  firstName: "Michael",
  lastName: "Chapman",
  phoneNumber: "3175550123",
  email: "michaelchapman@example.com",
  positionTitle: "Surgeon",
};

const sampleAccountB = {
  name: "Brighton Clinic - SAMPLE",
  address: "12438 Norton Avenue, Brighton, IN 46056",
  type: "Specialty",
  size: "Medium",
  yearlyRev: "500000",
};

const sampleNoteB = {
  title: "6/28 Meeting",
  description:
    "Met with Dr. Spark. Wants to get his partners on board before recommending our products for his patients. Reviewed recent publications and will discuss with all partners next meeting.",
  date: "2023-01-01",
};

const sampleContactB1 = {
  firstName: "Tony",
  lastName: "Spark",
  phoneNumber: "8125550173",
  email: "tonyspark@example.com",
  positionTitle: "Urologist",
};

const sampleContactB2 = {
  firstName: "Janet",
  lastName: "Matthews",
  phoneNumber: "8125550198",
  email: "janetmatthews@example.com",
  positionTitle: "Office Manager",
};

export const sampleData = {
  sampleAccountA: sampleAccountA,
  sampleNoteA: sampleNoteA,
  sampleContactA1: sampleContactA1,
  sampleContactA2: sampleContactA2,
  sampleAccountB: sampleAccountB,
  sampleNoteB: sampleNoteB,
  sampleContactB1: sampleContactB1,
  sampleContactB2: sampleContactB2,
};
