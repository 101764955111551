import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RootStackNavProp, RootStackScreenProps } from "./types";
import { auth, useDeleteNote, useUpdateNote } from "../../api/src/hooks";
import { FloatingLabelInput } from "../components/FloatingTextInput";
import DateTimePicker from "../components/DateTimePicker";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../components/Alert";

export const NoteDetailPage: React.FC = () => {
  const route = useRoute<RootStackScreenProps<"NoteDetail">["route"]>();
  const navigation = useNavigation<RootStackNavProp>();
  const note = route.params?.note;
  const accountId = route.params?.accountId;

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && !user) {
      navigation.navigate("Login");
      return;
    }
  }, [user, loading]);

  const [editMode, setEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [title, setTitle] = useState(note.title);
  const [description, setDescription] = useState(note.description);
  const [date, setDate] = useState(note.date);

  useEffect(() => {
    navigation.setOptions({ title: note.title });
  }, [navigation]);

  const editClicked = () => {
    setEditMode(!editMode);
    checkDirty();
  };

  const checkDirty = () => {
    if (
      note.title != title ||
      note.description != description ||
      note.date != date
    ) {
      setDirty(true);
    } else if (
      note.title == title &&
      note.description == description &&
      note.date == date
    ) {
      setDirty(false);
    }
  };

  const saveClicked = async () => {
    const { didComplete, error } = await useUpdateNote(
      {
        ...note,
        title: title,
        description: description,
        date: date,
      },
      accountId
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem updating this note", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const deleteAction = async () => {
    const { didComplete, error } = await useDeleteNote(
      {
        ...note,
      },
      accountId
    );
    if (didComplete) {
      navigateBack();
    } else {
      Alert("Error", "There was a problem deleting this note", [
        { text: "OK", style: "cancel", onPress: () => {} },
      ]);
    }
  };

  const deleteClicked = () => {
    Alert(
      "Are you sure?",
      "This note cannot be recovered once deleted. Are you sure you want to proceed?",
      [
        {
          text: "Yes, delete this note",
          style: "destructive",
          onPress: deleteAction,
        },
        { text: "Cancel", style: "cancel", onPress: () => {} },
      ]
    );
  };

  const navigateBack = () => {
    navigation.goBack();
  };

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ alignItems: "center", justifyContent: "center" }}
    >
      <TouchableOpacity style={styles.editButton} onPress={editClicked}>
        <Text>{editMode ? "Done" : "Edit"}</Text>
      </TouchableOpacity>

      <View style={styles.inputView}>
        <FloatingLabelInput
          label="Title"
          value={title}
          editable={editMode}
          onChangeText={setTitle}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          height: 70,
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: 17,
          marginBottom: 10,
          width: "80%",
        }}
      >
        <Text style={{ fontSize: 16, color: "#aaa", paddingRight: 20 }}>
          Date
        </Text>
        <View style={styles.date}>
          <DateTimePicker
            value={date}
            onChange={setDate}
            disabled={!editMode}
          />
        </View>
      </View>

      <View style={styles.descriptionView}>
        <FloatingLabelInput
          label="Description"
          value={description}
          multiline
          editable={editMode}
          onChangeText={setDescription}
        />
      </View>

      <TouchableOpacity
        style={[
          styles.saveButton,
          !dirty || editMode ? styles.saveButtonDisabled : undefined,
        ]}
        disabled={!dirty || editMode}
        onPress={saveClicked}
      >
        <Text>Save</Text>
      </TouchableOpacity>

      {editMode && (
        <TouchableOpacity style={styles.deleteButton} onPress={deleteClicked}>
          <Text>Delete</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  date: {
    borderRadius: 10,
  },
  deleteButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#FAA0A0",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginTop: 10,
    marginBottom: 40,
  },
  descriptionView: {
    borderRadius: 10,
    width: "80%",
    height: 400,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  editButton: {
    alignSelf: "flex-end",
    alignItems: "center",
    backgroundColor: "#b3cee5",
    borderRadius: 10,
    width: 70,
    padding: 10,
    margin: 10,
  },
  inputView: {
    borderBottomWidth: 3,
    borderRadius: 10,
    borderColor: "#b3cee5",
    width: "80%",
    height: 70,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  saveButton: {
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#98cd98",
    borderRadius: 10,
    width: "90%",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
  },
  saveButtonDisabled: {
    backgroundColor: "#DBDBD0",
  },
});
